import { defineStore } from 'pinia';


export const useBaseStore = defineStore('base', {
    state: () => ({
        headerAddWorkflowActive: false,
        headerAddChatbotActive: false,
        headerAddKnowledgeActive: false,
    })
})
