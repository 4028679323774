<script lang="ts" setup>
  import { reactive, nextTick } from 'vue';
  import { StepinHeaderAction } from 'stepin';
  import Notice from '@/components/notice/Notice.vue';
  import { BellOutlined } from '@ant-design/icons-vue';
  import Fullscreen from '../fullscreen/Fullscreen.vue';
  import { useRoute, useRouter } from 'vue-router'
  import { useBaseStore } from '@/store/base';

  const baseStore = useBaseStore()


  defineEmits<{
    (e: 'showSetting'): void;
  }>();

  const router = useRouter()
  const route = useRoute();

  async function addKnowledge() {
    console.log('addKnowledge route', route);
    if (route.path !== '/knowledge_base') {
      await router.push(`/knowledge_base`);
      baseStore.headerAddKnowledgeActive = true;
    }
    else {
      baseStore.headerAddKnowledgeActive = true;
    }
  }

  async function addChatbot() {
    console.log('addChatbot route', route);
    if (route.path !== '/myapp/release') {
      await router.push(`/myapp/release`);
      baseStore.headerAddChatbotActive = true;
    }
    else {
      baseStore.headerAddChatbotActive = true;
    }
  }

  async function addWorkflow() {
    console.log('addWorkflow route', route);
    if (route.path !== '/myapp/release') {
      await router.push(`/myapp/release`);
      baseStore.headerAddWorkflowActive = true;
    }
    else {
      baseStore.headerAddWorkflowActive = true;
    }
  }

  const noticeList = reactive([
    {
      title: '消息',
      list: [
        {
          title: '15901177524',
          content: '欢迎加入',
          img: 'src/assets/avatar/face-1.jpg',
          time: 12,
        },
      ],
    },
    // {
    //   title: '动态',
    //   list: [
    //     {
    //       title: '影佑',
    //       content: 'xxxxxxxxxxxxxxxxxx',
    //       img: 'http://i2.hdslb.com/bfs/face/80b6731ccf865ca7a4ac17e6e8848fd0e34c1b91.jpg',
    //       time: 0,
    //     },
    //   ],
    // },
    // {
    //   title: '通知',
    //   list: [
    //     {
    //       title: '影佑',
    //       content: 'xxxxxxxxxxxxxxxxxx',
    //       img: 'http://i2.hdslb.com/bfs/face/80b6731ccf865ca7a4ac17e6e8848fd0e34c1b91.jpg',
    //       time: 0,
    //     },
    //   ],
    // },
  ]);
</script>
<template>
  <!-- <StepinHeaderAction>
    <a-input placeholder="开始搜索...">
      <template #prefix>
        <search-outlined />
      </template>
    </a-input>
  </StepinHeaderAction> -->
  <!-- <StepinHeaderAction>
    <DayNightSwitch />
  </StepinHeaderAction> -->
  <!-- <StepinHeaderAction>
    <a class="action-item" href="http://github.com/stepui/stepin-template" target="_blank">
      <GithubOutlined />
    </a>
  </StepinHeaderAction>
  <StepinHeaderAction>
    <a class="action-item" href="http://gitee.com/stepui/stepin-template" target="_blank">
      <img class="gitee-logo" src="@/assets/gitee.svg" />
    </a>
  </StepinHeaderAction> -->
  <!-- <StepinHeaderAction>
    <div class="action-item setting" @click="$emit('showSetting')">
      <SettingOutlined />
    </div>
  </StepinHeaderAction> -->

  <!-- <a-popover placement="bottomRight">
    <StepinHeaderAction>
      <div class="action-item notice">
        <BellOutlined />
      </div>
    </StepinHeaderAction>
    <template #content>
      <Notice :data-source="noticeList" />
    </template>
  </a-popover> -->
  <StepinHeaderAction>
    <a-dropdown>
      <a class="ant-dropdown-link" @click.prevent>
        <PlusCircleFilled :style="{fontSize: '24px', marginTop: '4px'}"/>
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item>
            <a @click="addKnowledge">创建知识库</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="addChatbot">创建对话</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="addWorkflow">创建工作流</a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
<!--    <a-button type="link" ghost @click="addWorkflow"><PlusOutlined class="text-lg" />创建工作流</a-button>-->
  </StepinHeaderAction>
  <StepinHeaderAction>
    <Fullscreen class="-mx-xs -my-sm h-[56px] px-xs py-sm flex items-center" target=".stepin-layout" />
  </StepinHeaderAction>
</template>
<style scoped lang="less">
  .gitee-logo {
    width: 20px;
  }
  .action-item {
    font-size: 20px;
    height: 100%;
    margin: 0 -8px;
    padding: 0 4px;
    line-height: 40px;
    display: flex;
    align-items: center;

    &.setting {
      font-size: 18px;
    }

    &.notice {
      font-size: 18px;
    }
  }
</style>
