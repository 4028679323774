<template>
  <ThemeProvider is-root v-bind="themeConfig" :apply-style="false">
    <stepin-view
      :system-name="systemName"
      :logo-src="logoSrc"
      :class="`${contentClass}`"
      :user="user"
      :navMode="navigation"
      :useTabs="useTabs"
      :themeList="themeList"
      v-model:show-setting="showSetting"
      v-model:theme="theme"
      v-model:collapsed="collapsed"
      @themeSelect="configTheme"
    >
      <template #headerActions>
        <HeaderActions @showSetting="showSetting = true" />
      </template>
      <template #pageFooter>
        <PageFooter />
      </template>
      <template #themeEditorTab>
        <a-tab-pane tab="其它" key="other">
          <Setting />
        </a-tab-pane>
      </template>
    </stepin-view>
  </ThemeProvider>
  <template>
    <a-drawer
      title="修改密码"
      :closable="true"
      size="large"
      :visible="showChangePassword"
      :destroyOnClose="true"
      @close="changeShowChangePasswordVisible(false)"
    >
      <template #extra>
        <a-button style="margin-right: 8px" @click="changeShowChangePasswordVisible(false)">取消</a-button>
        <a-button type="primary" @click="changePassword">保存</a-button>
      </template>
      <a-form ref="form" :model="formData" :labelCol="{ span: 3 }" :wrapperCol="{ span: 8, offset: 0 }">
        <a-form-item required name="password" label="原密码">
          <a-input-password v-model:value="formData.password" placeholder="请输入原密码">
            <template #iconRender="v">
              <EyeTwoTone v-if="v"></EyeTwoTone>
              <EyeInvisibleOutlined v-else></EyeInvisibleOutlined>
            </template>
          </a-input-password>
        </a-form-item>
        <a-form-item required name="new_password" label="新密码">
          <a-input-password v-model:value="formData.new_password" placeholder="请输入新密码">
            <template #iconRender="v">
              <EyeTwoTone v-if="v"></EyeTwoTone>
              <EyeInvisibleOutlined v-else></EyeInvisibleOutlined>
            </template>
          </a-input-password>
        </a-form-item>
      </a-form>
    </a-drawer>
  </template>
  <template>
    <a-drawer
      title="基本信息"
      :closable="true"
      size="large"
      :visible="showPersonal"
      :destroyOnClose="true"
      @close="showPersonal = false"
    >
      <template #extra>
        <a-button @click="showPersonal = false">取消</a-button>
      </template>
      <a-form ref="form" :model="formData" :labelCol="{ span: 3 }" :wrapperCol="{ span: 8, offset: 0 }">
        <a-form-item name="avatar" label="头像">
          <a-upload list-type="picture-card" accept=".png,.jpg,.jpeg,.webp,.gif"
              v-model:fileList="formDataInput.image_urls"
              :show-upload-list="false"
              :customRequest="customRequest"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
            <div>
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 100px;height: 100px;"/>
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </div>
          </a-upload>
        </a-form-item>
      </a-form>
    </a-drawer>
  </template>
  <login-modal :unless="['/login']" />
</template>

<script lang="ts" setup>
  import { onMounted, reactive, computed, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAccountStore, useMenuStore, useSettingStore, storeToRefs } from '@/store';
  import { PageFooter, HeaderActions } from '@/components/layout';
  import Setting from './components/setting';
  import { LoginModal } from '@/pages/login';
  import { configTheme, themeList } from '@/theme';
  import { ThemeProvider } from 'stepin';
  import http from '@/store/http';
  import { EyeTwoTone, EyeInvisibleOutlined, CompassTwoTone } from '@ant-design/icons-vue';
  import { message } from 'ant-design-vue';
  import { changeAccountPassword, changeAccountAvatar } from "@/api/account";
  import OSS from 'ali-oss';
  import { OssStsTokenProps } from '@/store/thirdparty';
  import { Response } from '@/types';
  import { v4 as uuidv4 } from 'uuid';
  import { useThemeStore } from 'stepin/es/theme-provider';

  const { setPrimaryColor } = useThemeStore();
  setPrimaryColor({DEFAULT: '#2B59E6'});

  let ossStsToken = ref<OssStsTokenProps>();
  const accountStore = useAccountStore();
  const { logout, profile } = accountStore;
  const baseFilePath = ref<string>("file/account/avatar");
  const imageUrl = ref("");

  const systemName = import.meta.env.VITE_SITE_NAME || "Sapientia";

  const collapsed = ref(true);

  if (http.checkAuthorization()) {
    // 获取个人信息
    profile().then((response) => {
      const { account } = response;
      console.log(account)
      user.name = account.username;
      user.avatar = account.avatar;
      imageUrl.value = account.avatar;
      // isAdmin.value = account.is_superuser;
      const url = import.meta.env.VITE_API_URL;
      const isWeworkBotEnv = url.search("chat.isapientia.com") === -1;
      console.log("isWeworkBotEnv:", isWeworkBotEnv)

      useMenuStore().getMenuList(account.is_superuser, isWeworkBotEnv);
    });
  }
  function toggleCollapsed() {
    collapsed.value = !collapsed.value;
  }

  const formData = reactive<any>({
    password: "",
    new_password: "",
  });

  const formDataInput = ref({
    'image_urls': [],
  });
  let fileDict = ref({});

  const showSetting = ref(false);
  const showPersonal = ref(false);
  const showChangePassword = ref(false);
  const router = useRouter();

  const { navigation, useTabs, theme, contentClass } = storeToRefs(useSettingStore());
  const themeConfig = computed(() => themeList.find((item) => item.key === theme.value)?.config ?? {});

  const logoSrc = computed(() => {
    return systemName && systemName !== 'Sapientia'?"":"https://res.isapientia.com/img/vite.svg";
    // return systemName && systemName !== 'Sapientia'?"https://res.isapientia.com/img/tsinghua_logo.png":"https://res.isapientia.com/img/vite.svg";
  });

  const user = computed(() => {
    return{
        name: accountStore.account.username,
        avatar: accountStore.account.avatar || "https://res.isapientia.com/img/avatar-default.png",
        menuList: [
          { title: '基本信息', key: 'personal', icon: 'UserOutlined', onClick: () => (showPersonal.value = true) },
          { title: '修改密码', key: 'changePassword', icon: 'FormOutlined', onClick: () => (showChangePassword.value = true) },
          // { title: '设置', key: 'setting', icon: 'SettingOutlined', onClick: () => (showSetting.value = true) },
          { type: 'divider' },
          {
            title: '退出登录',
            key: 'logout',
            icon: 'LogoutOutlined',
            onClick: logoutAccount,
          },
        ],
      }
    }
  );
  function logoutAccount(){
    logout().then(() => {
      // window.localStorage.clear();
      // 清理掉df的token
      localStorage.removeItem("console_token")
      router.push('/login')
    })
  }
  function changeShowChangePasswordVisible(visible){
    showChangePassword.value = visible;
  }
  const loading = ref(false);
  const form = ref<FormInstance>();

  function changePassword() {
    loading.value = true;
    form.value
      ?.validate()
      .then(() => {
        changeAccountPassword(formData).then((response) => {
          console.log(response);
          const {code} = response;
          if (code === 0){
            message.success(`修改成功`);
            changeShowChangePasswordVisible(false);
            form.value?.resetFields();
          }
        }).catch((e) => {
          console.error(e);
        });
      })
      .finally(() => {
        loading.value = false;
      });
  }

  async function getOssStsToken() {
    return http
      .request<OssStsTokenProps, Response<OssStsTokenProps>>('/thirdparty/oss/sts_token?public=1', 'GET')
      .then((res) => {
        console.log(res);
        const { data } = res;
        ossStsToken.value = data;
        return data;
      })
      .finally(() => (console.log("end")));
  }

  const beforeUpload: UploadProps['beforeUpload'] = async file => {
    const fileExtName = file.name.substring(file.name.lastIndexOf(".") + 1);
    const fileName = uuidv4() + "." + fileExtName;
    const oss_key = baseFilePath.value + '/' + fileName;

    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error('单个图片最大1MB!');
      return false;
    }

    const fileData = {name: file.name, oss_key: oss_key, size: file.size, status: 0, url: ""};
    console.log(fileData);
    fileDict.value[file.uid] = fileData;
    return true;
  }

  const handleChange = async (info: UploadChangeParam) => {
    const status = info.file.status;
    console.log(info)
    console.log(fileDict.value)

    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }
    if (info.file.status === 'done') {
      imageUrl.value = fileDict.value[info.file.uid]["url"];
    }
    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }

    if(status === 'removed') {
      delete fileDict.value[info.file.uid];
    }
  };

  async function customRequest(info) {
    console.log(info);
    const use_cname = !ossStsToken.value.endpoint.includes(".aliyuncs.com");
    const client = new OSS({
      region: ossStsToken.value.region,
      accessKeyId: ossStsToken.value.access_key_id,
      accessKeySecret: ossStsToken.value.access_key_secret,
      stsToken: ossStsToken.value.security_token,
      bucket: ossStsToken.value.bucket_name,
      endpoint: ossStsToken.value.endpoint,
      useFetch: true,
      cname: use_cname,
      secure: true,
    });

    const oss_key = fileDict.value[info.file.uid]["oss_key"];
    if(info.file){
      console.log(oss_key)
      await client.multipartUpload(oss_key, info.file, {
        parallel: 4,
        partSize: 100 * 1024,
        progress: function (percent) {
          // console.log(info.file.uid);
          console.log("progress is: ", percent * 100);
          console.log(formDataInput.value.image_urls)
          const fileIdx = formDataInput.value.image_urls.findIndex(obj => obj.uid === info.file.uid);
          console.log(fileIdx);
          formDataInput.value.image_urls[fileIdx].percent = percent * 100;
          loading.value = true;
          if (percent === 1){
            formDataInput.value.image_urls[fileIdx].status = "success";
            loading.value = false;
          }
        },
      }).then(res => {
        console.log('结果:', res);
        fileDict.value[info.file.uid]["status"] = 1;
        fileDict.value[info.file.uid]["url"] = ossStsToken.value.endpoint + '/' + oss_key;
        fileDict.value = fileDict.value;
        imageUrl.value = fileDict.value[info.file.uid]["url"];
        accountStore.account.avatar = imageUrl.value;
        changeAccountAvatar({avatar: imageUrl.value}).then((response) => {
          console.log(response);
          const {code} = response;
          if (code === 0){
            message.success(`更改成功`);
          }
        }).catch((e) => {
          console.error(e);
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  onMounted(async () => {
    if(http.checkAuthorization()){
      await getOssStsToken();
    }
    console.log("onMounted:", systemName, logoSrc.value);
  });

</script>

<style lang="less">
  .stepin-view {
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: theme('colors.primary.500');
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: theme('colors.primary.400');

      &:hover {
        background-color: theme('colors.primary.500');
      }
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 4px;
      background: theme('backgroundColor.layout');
    }
  }

  html {
    height: 100vh;
    overflow-y: hidden;
  }

  body {
    margin: 0;
    height: 100vh;
    overflow-y: hidden;
  }
  .stepin-img-checkbox {
    @apply transition-transform;
    &:hover {
      @apply scale-105 ~"-translate-y-[2px]";
    }
    img {
      @apply shadow-low rounded-md transition-transform;
    }
  }
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .stepin-header-left{
    display: flex;
    align-items: center;
    transform: rotate(180deg);
  }

  // .stepin-layout-side {
  //   width: var(--width-side);
  //   overflow-y: auto;
  // }

  // .ant-menu-submenu-popup::before {
  //   position: absolute;
  //   width: 200px;
  //   top: -7px;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: -1;
  //   width: 100%;
  //   height: 100%;
  //   opacity: 0.0001;
  //   content: ' ';
  // }

  // 设置菜单样式
// .ant-menu,.ant-menu-sub,.ant-menu-inline{
//   color: white;
//   background-color: #044d50 !important;
// }

// 设置子菜单展开样式
// .ant-menu-submenu > .ant-menu {
//   background-color: rgb(16, 71, 83) !important;
// }

// .stepin-submenu-popup {
//   width: 400px;
//   min-width: 400px;
// }
// 去掉右边框
// .ant-menu-inline {
//   border: none;
//   margin: 0px;
// }
// 设置 a 链接样式
// .ant-menu-item a {
//   color: white !important;
// }
// .ant-menu-item a:hover {
//   color: #044d50 !important;
// }
// .ant-menu-item a:active:before {
//   color: #044d50 !important;
// }
// .ant-menu-item a:active:after {
//   color: #044d50 !important;
// }
// 下拉箭头样式
// .ant-menu-submenu-arrow {
//   color: white !important;
// }

// 菜单收缩样式
// .ant-menu.ant-menu-inline-collapsed {
//   width: 400px;
// }
// 设置收缩后的右边框
// .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
//   border-right: none;
// }
// 气泡框样式
// .ant-tooltip-inner,.ant-tooltip-arrow-content{
//   background-color: rgb(16, 71, 83) !important;
// }
// .ant-tooltip-inner a {
//   color: white !important;
// }
// 收缩的样式
// .ant-layout-sider-trigger {
//   background-color: rgb(4, 77, 100) !important;
// }
</style>
